import { fetch, post } from 'api/base/axios'

const URL = {
  applyDiyUrl: '/curriculum/experiment/diy/apply/',
  applyCourseExptUrl: '/curriculum/experiment/course/expt/apply/',
  applyDiyExptUrl: '/curriculum/experiment/diy/expt/apply/',
  getOpenLabHostUrl: '/curriculum/experiment/openlab/host/',
  recordLogUrl: '/curriculum/experiment/record/log/',
}


export function applyDiy () {
  return fetch(URL.applyDiyUrl)
}

export function applyDiyExpt (expt_uuid) {
  return fetch(URL.applyDiyExptUrl + expt_uuid + "/")
}

export function applyCourseExpt (expt_uuid) {
  return fetch(URL.applyCourseExptUrl + expt_uuid + "/")
}

export function getOpenLabHost () {
  return fetch(URL.getOpenLabHostUrl)
}

export function recordLog (action) {
  return post(URL.recordLogUrl, {"action": action})
}
