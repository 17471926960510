<template>
  <div v-if="has_locked">
    <el-row>
      <el-col style="padding-top:100px;">
        <el-result
          icon="warning"
          title="实验功能已被禁用"
          sub-title="如有疑问请联系我们"
        >
        </el-result>
      </el-col>
    </el-row>
  </div>
  <div class="diy_detail" v-else>
    <iframe :src="expt_url" scrolling="auto" frameborder="0"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      oallowfullscreen="true"
      msallowfullscreen="true"
    ></iframe>
  </div>
</template>

<script>
import { applyDiyExpt, recordLog } from 'api/experiment/diy'
export default {
  components: {},
  async created() {
    if (this.$route.params.uuid != undefined) {
      this.expt_uuid = this.$route.params.uuid;
    }
    const url_query = this.$route.query;
    if (url_query.from) {
      this.from_url = url_query.from;
    }
    await this.getExptUrl(this.expt_uuid)
  },
  data() {
    return {
      expt_uuid: '',
      expt_url: '',
      has_locked: false,
      from_url: '/home'
    }
  },
  mounted() {
    const that = this;

    window.addEventListener("message", function (event) {
      var data = event.data;
      switch (data) {
        case '51_diy_expt_back': {
          window.location.href = that.from_url;
        } break;
        case '51_diy_expt_closed': {
          window.location.href = that.from_url;
        } break;
        case '51_404': {
          window.location.href = "/404";
        } break;
        case '51_423': {
          that.has_locked = true;
        } break;
        default: {
          if (typeof data === 'object' && data.hasOwnProperty("diy_action")) {
            const action = data.diy_action;
            const name = data.name;
            switch (action) {
              case 'close': {
                recordLog(`结束了开放实验，实验名称为${name}。`)
                window.location.href = that.from_url;
              } break;
            }
          }
        }
      }
    }, '*')
  },
  methods: {
    async getExptUrl(expt_uuid) {
      const that = this;
      await applyDiyExpt(expt_uuid).then(result => {
        if (result.succeed) {
          that.expt_url = result.url;
        } else {
          window.location.href = "/404";
        }
      }).catch(err => {
        if (!err.__CANCEL__) {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        }
      });
    }
  }
  }
</script>

<style lang="scss" scoped>
  .diy_detail {
    display:flex;
    height:100vh;
  }
  iframe {
    width:100vw;
    flex:1;
  }
</style>
